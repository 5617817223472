import { useState } from 'react';
import { Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

import { CarApp } from '../../interfaces/CarApp';
import PublishDialog from './publish-dialog';
import drawIcon from '../../utils/draw-icon';
import CarAppTable from '../../components/CarAppTable';
import { MRT_Row } from 'material-react-table';

interface PublishTableProps {
  isLive: boolean,
  metadata: CarApp[],
  publishFunction: Function,
}

export default function PublishTable(
  {
    isLive,
    metadata,
    publishFunction,
  }: PublishTableProps) {
  const [appToPublish, setAppToPublish] = useState<CarApp>();

  const closePublishDialog = () => {
    setAppToPublish(undefined);
  };

  const openPublishDialog = (app: CarApp) => {
    setAppToPublish(app);
  };

  return (
    <Box sx={{ width: '80%', marginBottom: '2em' }}>
        <CarAppTable
            title={isLive ? 'Recall Apps' : 'Publish Apps'}
            data={metadata}
            renderRowActions={({ row } : { row: MRT_Row }) =>
                <div>
                    <Tooltip title={isLive ? 'Recall' : 'Publish'}>
                        <IconButton onClick={() => openPublishDialog(row.original as CarApp)}>
                            {drawIcon(isLive ? 'recall' : 'publish')}
                        </IconButton>
                    </Tooltip>
                </div>
            }
        />
      <PublishDialog
          app={appToPublish}
          onConfirm={() => publishFunction(appToPublish!)}
          onAbort={closePublishDialog}
      />
    </Box>
  );
}
