import React from 'react';
import { useMatch, useNavigate } from 'react-router-dom';
import { NavItem, NavLink } from 'reactstrap';

interface NavigationItemProps {
  path: string
  text: string
}

const NavigationItem = ({
  path,
  text,
}: NavigationItemProps) => {
  const navigate = useNavigate();
  const active = Boolean(useMatch(path));

  const styleObj = {
    cursor: 'pointer',
    borderBottom: active ? '2px solid #001e50' : '',
  };

  // @ts-expect-error
  function handleKeyDown(event: KeyboardEvent<HTMLElement>) {
    if (event.key === 'Enter') {
      navigate(path);
    }
  }

  function handleClick(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    navigate(path);
  }

  return (
    <NavItem
      active={active}
      id={path}
      onKeyDown={handleKeyDown}
      tabIndex={0}
      onClick={handleClick}
    >
      <NavLink style={styleObj} className="text-truncate">
        {text}
      </NavLink>
    </NavItem>
  );
};

export default NavigationItem;
