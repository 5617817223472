import { CarApp } from '../../interfaces/CarApp';
import { AuthContextType } from '../../context/auth-context';
import { MessageContextType } from '../../context/message-context';
import { loadMetadata } from '../../utils/load-metadata';

export const PUBLISH_URL = '/api/publish-app-';
export const DEPUBLISH_URL = '/api/depublish-app';

// TODO copy from portal-backend/depublish-app.ts delete when its moved to commons
const VOLUME_UPLOAD_BRANDS = ['vw', 'skoda', 'seat', 'man', 'ford']; // volume brand vs. premium brand

export default function publishCarApp(
  app: CarApp,
  setIsLoading: Function,
  setData: Function,
  authContext: AuthContextType,
  messageContext: MessageContextType,
) {

  setIsLoading(true);

  const isVolumeBrand = VOLUME_UPLOAD_BRANDS.includes(app.brand);
  const oem = isVolumeBrand ? 'vws' : 'audis';
  const endpoint = app.isLive ? DEPUBLISH_URL : (PUBLISH_URL + oem);
  const url = `${authContext.getHostUrl() + endpoint}?id=${app.id}`;

  if (authContext.isAuthenticated()) {
    fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${authContext.getUser()?.accessToken}`,
      },
    })
      .then(async (response: Response)=> {
        if (response.ok) {
          messageContext.addSuccess(`Successfully ${app.isLive ? 'recalled' : 'published'} ${app.name} ${app.version}.`);
        } else {
          messageContext.addError(`An error occurred during the ${app.isLive ? 'recall' : 'publish'} of ${app.name}.`);
        }
      })
      .then(() => loadMetadata(setIsLoading, authContext, setData, messageContext))
      .finally(() => {setIsLoading(false);});
  }
}
