import { Button, Dialog, DialogTitle } from '@mui/material';
import DialogProps from '../dialog-props';

export default function PublishDialog(props: DialogProps) {
  return <Dialog onClose={props.onAbort} open={!!props.app}>
    <DialogTitle>{props.app?.isLive ? 'Recall' : 'Publish'} {props.app?.name} {props.app?.version} for {props.app?.brand}?</DialogTitle>
        <div className="p-3 d-flex flex-row w-100 justify-content-around">
            <Button
                variant="contained"
                onClick={props.onConfirm}
                data-testid={`confirm-publish-button-${props.app?.id}`}
            >
                Ok
            </Button>
            <Button
                variant="outlined"
                onClick={props.onAbort}
                data-testid="abort-publish-button"
            >
                Abort
            </Button>
        </div>
    </Dialog>;
}