import * as React from 'react';
import { styled } from '@mui/material';
import { Card, CardTitle } from 'reactstrap';

import { useNavigate } from 'react-router-dom';
import { type ReactElement, useCallback } from 'react';

interface CardProps {
  name: string
  icon: ReactElement
  link: string
}

export default function DashboardCard(props: CardProps) {
  const navigate = useNavigate();
  const handleOnClick = useCallback(
    () => { navigate('/' + props.link, { replace: true }); },
    [navigate],
  );

  const [hover, setHover] = React.useState(false);

  const StyledCard = styled(Card)`
    cursor: pointer;
    &:hover {
      color: ${hover ? '#ffffff' : '#001e50'};
      background-color: ${hover ? '#001e50' : '#ffffff'};
    }
    height: 170px;
    width: 250px;
    min-width: 250px;
    justify-content: center;
  `;

  function setPrimaryColor() {
    setHover(true);
  }
  function setSecondaryColor() {
    setHover(false);
  }

  return (
    <StyledCard
      className="align-items-center p-5 col-lg-3 col-md-2 col-sm-12 m-3"
      sx={{ fontSize: '30px' }}
      onClick={handleOnClick}
      onMouseEnter={setPrimaryColor}
      onMouseLeave={setSecondaryColor}
      tabIndex={0}
    >
      {props.icon}
      <CardTitle className="p-2">{props.name}</CardTitle>
    </StyledCard>
  );
}
