import { CircularProgress, Grid } from '@mui/material';
import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../context/auth-context';
import { MessageContext } from '../../context/message-context';
import { CarApp } from '../../interfaces/CarApp';
import PublishTable from './publish-table';
import { loadMetadata } from '../../utils/load-metadata';
import publishCarApp from './publish-car-app';
import { useNavigate } from 'react-router-dom';


function Publish() {
  const [data, setData] = React.useState<CarApp[]>([]);
  const authContext = useContext(AuthContext);
  const messageContext = useContext(MessageContext);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (!authContext.hasAllowedRoles()) {
      navigate('/notallowed', { replace: true });
    } else {
      loadMetadata(setIsLoading, authContext, setData, messageContext);
    }
  }, []);

  function setupPublish(app: CarApp) {
    publishCarApp(
      app,
      setIsLoading,
      setData,
      authContext,
      messageContext,
    );
  }

  return (
      <Grid container justifyContent="center">
        {isLoading ? <CircularProgress /> :
          <Grid container spacing={2} pt={3} justifyContent="center">
              <PublishTable
                isLive={false}
                metadata={data.filter((carApp: CarApp) => !carApp.isLive)}
                publishFunction={setupPublish}
              />
              <PublishTable
                isLive
                metadata={data.filter((carApp: CarApp) => carApp.isLive)}
                publishFunction={setupPublish}
              />
          </Grid>
        }
      </Grid>
  );
}

export default Publish;


