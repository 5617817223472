import { green, grey } from '@mui/material/colors';
import {
  MaterialReactTable,
  MRT_Cell,
  MRT_ColumnDef,
  MRT_FullScreenToggleButton,
  MRT_Row,
  MRT_TableInstance,
  MRT_ToggleGlobalFilterButton,
  MRT_ToggleFiltersButton,
} from 'material-react-table';
import { CarApp, RegionUris } from '../interfaces/CarApp';
import { ReactNode, useEffect, useState } from 'react';
import { Box, Tooltip } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Cancel, Check, FilterAltOffOutlined, FilterAltOutlined, ViewColumnOutlined } from '@mui/icons-material';
import semver from 'semver';

interface RenderRowActionsProps {
  cell: MRT_Cell,
  row: MRT_Row,
  table: MRT_TableInstance
}

interface CarAppTableProps {
  title?: string,
  data: CarApp[],
  renderRowActions: ({ cell, row, table }: RenderRowActionsProps) => ReactNode
}

const CELL_TEXT_STYLE = {
  fontSize: '0.875rem',
  lineHeight: 1.4,
};
const HEADER_BG = '#fff';
const HEADER_COLOR = '#000';
const HEADER_STYLE = {
  sx: {
    background: HEADER_BG,
    color: HEADER_COLOR,
  },
};

function cleanVersion(ver: string) {
  return ver
    ? ver
      .split('-')[0]
      .split('.')
      .map(s => parseInt(s))
      .join('.')
    : '0.0.0';
}

export default function CarAppTable(
  {
    title,
    data,
    renderRowActions,
  } : CarAppTableProps,
) {

  const [oemFilterOptions, setOemFilterOptions] = useState<string[]>([]);
  const [cluFilterOptions, setCluFilterOptions] = useState<string[]>([]);
  const [genFilterOptions, setGenFilterOptions] = useState<string[]>([]);
  const [regionsFilterOptions, setRegionsFilterOptions] = useState<string[]>([]);

  useEffect(() => {
    setOemFilterOptions(Array.from(new Set(data.map(d => d.brand))));
    setCluFilterOptions(Array.from(new Set(data.flatMap(d => d.clu ?? []))));
    setGenFilterOptions(Array.from(new Set(data.map(d => d.gen))));
    setRegionsFilterOptions(Array.from(new Set(data.flatMap(d => d.regions.map(r => r.region)))));
  }, [data]);

  const columns: MRT_ColumnDef<CarApp>[] = [
    {
      id: 'all',
      header: '',
      enableHiding: false,
      columns: [
        {
          accessorKey: 'name',
          header: 'Name',
          muiTableHeadCellProps: HEADER_STYLE,
          // size: 100,
          Cell: ({ renderedCellValue, cell }) => (
              <Tooltip title={cell.getValue<string>()}>
                <Typography sx={CELL_TEXT_STYLE}>
                  {renderedCellValue}
                </Typography>
              </Tooltip>
          ),
        },
        {
          accessorKey: 'brand',
          header: 'OEM',
          filterVariant: 'multi-select',
          filterSelectOptions: oemFilterOptions,
          muiTableHeadCellProps: HEADER_STYLE,
          size: 50,
        },
        {
          accessorKey: 'clu',
          header: 'Cluster',
          filterVariant: 'multi-select',
          filterSelectOptions: cluFilterOptions,
          muiTableHeadCellProps: HEADER_STYLE,
          size: 50,
        },
        {
          accessorKey: 'gen',
          header: 'Gen.',
          filterVariant: 'multi-select',
          filterSelectOptions: genFilterOptions,
          muiTableHeadCellProps: HEADER_STYLE,
          size: 50,
        },
        {
          accessorKey: 'version',
          header: 'Version',
          enableGrouping: false,
          sortingFn: (rowA, rowB) => {
            const verA = semver.valid(cleanVersion(rowA.getValue<string>('version')))!;
            const verB = semver.valid(cleanVersion(rowB.getValue<string>('version')))!;
            return semver.compare(verA, verB);
          },
          muiTableHeadCellProps: HEADER_STYLE,
          size: 100,
        },
        {
          accessorKey: 'regions',
          header: 'Regions',
          enableGrouping: false,
          filterVariant: 'multi-select',
          filterSelectOptions: regionsFilterOptions,
          filterFn: (row, _id, filterValues: string[]) => (
            filterValues.every(value =>
              row.getValue<RegionUris[]>('regions')
                .map(r => r.region)
                .includes(value),
            )
          ),
          muiTableHeadCellProps: HEADER_STYLE,
          // size: 100,
          Cell: ({ cell }) => (
            cell.getValue<RegionUris[]>()
              .map((region) => region.region)
              .join(', ')
          ),
          enableSorting: false,
        },
        {
          accessorKey: 'isLive',
          header: 'Live',
          enableGrouping: false,
          enableColumnFilter: false,
          muiTableHeadCellProps: HEADER_STYLE,
          size: 10,
          Cell: ({ cell }) => (
            cell.getValue<boolean>()
              ? <Check sx={{ color: green[400] }}/>
              : <Cancel sx={{ color: grey[400] }}/>
          ),
        },
      ],
    },
    {
      id: 'uploadGroup',
      header: 'Uploaded',
      muiTableHeadCellProps: {
        sx: {
          background: HEADER_BG,
          color: HEADER_COLOR,
          borderLeft: `1px solid ${grey[400]}`,
          borderBottom: 'none',
        },
      },
      columns: [
        {
          accessorKey: 'uploadedBy',
          header: 'By',
          size: 50,
          enableColumnDragging: false,
          enableColumnOrdering: false,
          enableGrouping: false,
          enableColumnFilter: false,
          muiTableHeadCellProps: {
            sx:{
              background: HEADER_BG,
              color: HEADER_COLOR,
              borderLeft: `1px solid ${grey[400]}`,
            },
          },
        },
        {
          accessorFn: (row) => new Date(row.uploadDate),
          id: 'uploadDate',
          header: 'Date',
          muiTableHeadCellProps: HEADER_STYLE,
          size: 50,
          enableColumnDragging: false,
          enableColumnOrdering: false,
          enableGrouping: false,
          enableColumnFilter: false,
          Cell: ({ cell }) => cell.getValue<Date>().toLocaleDateString(),
        },
      ],
    },
    {
      id: 'publishGroup',
      header: 'Published',
      muiTableHeadCellProps: {
        sx: {
          background: HEADER_BG,
          color: HEADER_COLOR,
          borderLeft: `1px solid ${grey[400]}`,
          borderRight: `1px solid ${grey[400]}`,
          borderBottom: 'none',
        },
      },
      columns: [
        {
          accessorKey: 'publishedBy',
          header: 'By',
          size: 50,
          enableColumnDragging: false,
          enableColumnOrdering: false,
          enableGrouping: false,
          enableColumnFilter: false,
          muiTableHeadCellProps: {
            sx:{
              background: HEADER_BG,
              color: HEADER_COLOR,
              borderLeft: `1px solid ${grey[400]}`,
            },
          },
        },
        {
          accessorFn: (row) => new Date(row.publishDate || 0),
          id: 'publishDate',
          header: 'Date',
          size: 50,
          enableColumnDragging: false,
          enableColumnOrdering: false,
          enableGrouping: false,
          enableColumnFilter: false,
          filterFn: 'lessThanOrEqualTo',
          sortingFn: 'datetime',
          Cell: ({ cell, row }) => (
            row.original.isLive
              ? cell.getValue<Date>().toLocaleDateString()
              : ''
          ),
          muiTableHeadCellProps: {
            sx: {
              background: HEADER_BG,
              color: HEADER_COLOR,
              borderRight: `1px solid ${grey[400]}`,
            },
          },
        },
      ],
    },
  ];

  return (
        <MaterialReactTable
            // @ts-ignore
            columns={columns}
            data={data}

            enableColumnActions={false}
            enableGrouping
            enableColumnOrdering
            enableDensityToggle={false}
            enableHiding={false}

            icons={{
              FilterListIcon: (props: any) => <FilterAltOutlined {...props} />,
              FilterListOffIcon: () => <FilterAltOffOutlined />,
              ViewColumnIcon: () => <ViewColumnOutlined />,
            }}

            muiTableProps={{
              sx: {
                tableLayout: 'grid',
              },
            }}
            muiTableHeadRowProps={{
              sx: {
                boxShadow: 'none',
              },
            }}
            muiTableHeadCellProps={{
              sx: {
                borderBottom: 'none',
                boxShadow: 'none',
              },
            }}

            muiTableBodyProps={{
              sx: () => ({
                '& tr:nth-of-type(odd)': {
                  backgroundColor: grey[100],
                },
              }),
            }}
            displayColumnDefOptions={{
              'mrt-row-actions': {
                header: '',
                muiTableBodyCellProps: {
                  sx: {
                    backgroundColor: 'inherit',
                    boxShadow: 'none',
                  },
                },
              },
            }}
            initialState={{
              density: 'compact',
              columnPinning: {
                left: [],
                right: ['mrt-row-actions'],
              },
            }}

            enableRowActions
            positionActionsColumn='last'

            renderRowActions={renderRowActions}

            renderToolbarInternalActions={({ table }) => (
              <Box
                  sx={{
                    display: 'flex',
                  }}
              >
                <MRT_ToggleGlobalFilterButton table={table} />
                <MRT_ToggleFiltersButton table={table}/>
                <MRT_FullScreenToggleButton table={table}/>
              </Box>
            )}

            renderTopToolbarCustomActions={({}) => (
                <Typography color='primary'>
                  {title}
                </Typography>
            )}
        />
  );
}
