import 'bootstrap/dist/css/bootstrap.min.css';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Upload from './components/upload';
import './index.css';
import About from './pages/about';
import LogoutSuccess from './pages/logout-success';
import Overview from './pages/overview/overview';
import Publish from './pages/publish/publish';
import reportWebVitals from './reportWebVitals';
import Root from './root';
import Callback from './pages/callback';
import NotAllowed from './pages/not-allowed';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root/>,
    children: [
      {
        path: 'upload',
        element: <Upload/>,
      },
      {
        path: 'overview',
        element: <Overview/>,
      },
      {
        path: 'publish',
        element: <Publish/>,
      },
      {
        path: 'about',
        element: <About/>,
      },
      {
        path: 'callback',
        element: <Callback/>,
      },
      {
        path: 'logoutsuccess',
        element: <LogoutSuccess/>,
      },
      {
        path: 'notallowed',
        element: <NotAllowed/>,
      },
    ],
  },
]);

root.render(
  // <React.StrictMode>
        <RouterProvider router={router}/>,
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
