import { LogoutRounded } from '@mui/icons-material';
import { Card, CardText } from 'reactstrap';

const LogoutSuccess = () => (
  <Card className="align-items-center p-2 border-0">
    <LogoutRounded />
    <CardText className="p-2">
      You have successfully logged out.
    </CardText>
  </Card>
);
export default LogoutSuccess;
