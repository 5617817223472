import { Button, Dialog, DialogTitle } from '@mui/material';
import { red } from '@mui/material/colors';
import DialogProps from '../dialog-props';

export default function DeleteDialog(props: DialogProps) {
  return <Dialog open={!!props.app} onClose={props.onAbort}>
        <DialogTitle>Delete {props.app?.name} {props.app?.version} for {props.app?.brand}?</DialogTitle>
        {props.app?.isLive &&
            <div
                className="p-2 w-100 d-flex flex-row justify-content-center"
                data-testid="is-live-warning"
            >
                The App is currently&nbsp;<b style={{ color: red[500] }}>live</b>!
            </div>
        }
        <div className="p-3 d-flex flex-row w-100 justify-content-around">
            <Button
                variant="contained"
                onClick={props.onConfirm}
                data-testid="confirm-delete-button"
            >
                Ok
            </Button>
            <Button
                variant="outlined"
                onClick={props.onAbort}
                data-testid="abort-delete-button"
            >
                Abort
            </Button>
        </div>
    </Dialog>;
}