import { Box, CircularProgress } from '@mui/material';
import { Outlet, useMatch } from 'react-router-dom';
import DisplayMessage from './components/display-message';
import Header from './components/header';
import AuthProvider, { AuthContext, AuthContextType } from './context/auth-context';
import MessageProvider from './context/message-context';
import Dashboard from './pages/dashboard';

export default function Root() {
  const onDashboardRoute = Boolean(useMatch('/'));
  const onCallbackRoute = Boolean(useMatch('/callback'));
  const onLogoutRoute = Boolean(useMatch('/logoutsuccess'));

  return (
    <>
      <div id="root-route">
        <MessageProvider>
          <AuthProvider>
            <AuthContext.Consumer>
              {(authContext: AuthContextType) => !authContext.isAuthenticated() && !onCallbackRoute && !onLogoutRoute ? (
                <Box sx={{ display: 'flex', width: '100%', height: '100vh', justifyContent: 'center', alignItems: 'center' }}>
                  <CircularProgress size={80}/>
                </Box>
              ) : (
                <>
                  <Header/>
                  {onDashboardRoute ? <Dashboard/> : <Outlet/>}
                </>
              )}
            </AuthContext.Consumer>
          </AuthProvider>
          <DisplayMessage/>
        </MessageProvider>
      </div>
    </>
  );
}
