import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { useContext } from 'react';
import { MessageContext } from '../context/message-context';

export default function DisplayMessage() {
  const messageContext = useContext(MessageContext);

  return (
    <>
      <Snackbar
        open={!!messageContext.info}
        onClose={messageContext.clearInfo}
        autoHideDuration={7000}
      >
        <Alert elevation={6} variant="filled" severity="info">
          <div className="container d-flex h-100">
            <div className="row justify-content-center align-self-center" data-testid="info-message">
              {messageContext.info}
            </div>
          </div>
        </Alert>
      </Snackbar>
      <Snackbar
        open={!!messageContext.success}
        onClose={messageContext.clearSuccess}
        autoHideDuration={5000}
      >
        <Alert elevation={6} variant="filled" severity="success">
          <div className="container d-flex h-100">
            <div className="row justify-content-center align-self-center" data-testid="success-message">
              {messageContext.success}
            </div>
          </div>
        </Alert>
      </Snackbar>
      <Snackbar
        open={!!messageContext.error}
        onClose={messageContext.clearError}
      >
        <Alert elevation={6} variant="filled" severity="error">
          <div className="container d-flex h-100">
            <div className="row justify-content-center align-self-center" data-testid="error-message">
              {messageContext.error}
            </div>
          </div>
        </Alert>
      </Snackbar>
      <Snackbar
        open={!!messageContext.warning}
        onClose={messageContext.clearWarning}
        autoHideDuration={10000}
      >
        <Alert elevation={6} variant="filled" severity="warning">
          <div className="container d-flex h-100">
            <div className="row justify-content-center align-self-center" data-testid="warning-message">
              {messageContext.warning}
            </div>
          </div>
        </Alert>
      </Snackbar>
    </>
  );
}
