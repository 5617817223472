import { Nav, Navbar } from 'reactstrap';

import NavigationItem from './navigation-item';

const Navigation = () => (
  <Navbar
    light
    expand="md"
    className="Navbar-main align-items-center"
  >
    <Nav className="ml-auto h-100 flex-row ml-auto" navbar>
      <NavigationItem path="/overview" text="Overview" />
      <NavigationItem path="/upload" text="Upload" />
      <NavigationItem path="/publish" text="Publish" />
    </Nav>
  </Navbar>
);

export default Navigation;
