import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import { green, red } from '@mui/material/colors';
import { CloudDownload, CloudUpload } from '@mui/icons-material';

export default function drawIcon(type : 'delete' | 'download' | 'recall' | 'publish') {
  switch (type) {
    case 'delete':
      return <DeleteIcon/>;
    case 'download':
      return <DownloadIcon/>;
    case 'recall':
      return <CloudDownload sx={{ color: red[400] }}/>;
    case 'publish':
      return <CloudUpload sx={{ color: green[400] }}/>;
  }
}