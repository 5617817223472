import { createContext, useMemo, useState } from 'react';

export interface MessageContextType {
  error: string | null,
  addError: (caughtError: any) => void,
  clearError: () => void,
  success: string | null,
  addSuccess: (message: string) => void,
  clearSuccess: () => void,
  info:  string | null,
  addInfo: (message: string) => void,
  clearInfo: () => void,
  warning: string | null,
  addWarning: (message: string) => void,
  clearWarning: () => void,
}

/* eslint-disable @typescript-eslint/no-unused-vars */
export const MessageContext = createContext({
  error: '',
  addError: (_caughtError: any) => {},
  clearError: () => {},
  success: '',
  addSuccess: (_message: string) => {},
  clearSuccess: () => {},
  info: '',
  addInfo: (_message: string) => {},
  clearInfo: () => {},
  warning: '',
  addWarning: (_message: string) => {},
  clearWarning: () => {},
} as MessageContextType);
/* eslint-enable @typescript-eslint/no-unused-vars */

export default function MessageProvider({ children }: any) {
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [info, setInfo] = useState<string | null>(null);
  const [warning, setWarning] = useState<string | null>(null);

  const addError = (caughtError: string) => { setError(caughtError); };
  const addSuccess = (message: string) => { setSuccess(message); };
  const addInfo = (message: string) => { setInfo(message); };
  const addWarning = (message: string) => { setWarning(message); };

  const clearSuccess = () => { setSuccess(null); };
  const clearError = () => { setError(null); };
  const clearInfo = () => { setInfo(null); };
  const clearWarning = () => { setWarning(null); };

  const contextValue = useMemo(() => (
    {
      error,
      addError,
      clearError,
      addSuccess,
      clearSuccess,
      success,
      addInfo,
      clearInfo,
      info,
      addWarning,
      clearWarning,
      warning,
    }
  ), [
    error,
    addError,
    clearError,
    addSuccess,
    clearSuccess,
    success,
    addInfo,
    clearInfo,
    info,
    addWarning,
    clearWarning,
    warning,
  ]);

  return (
    <MessageContext.Provider value={contextValue}>
      {children}
    </MessageContext.Provider>
  );
}
