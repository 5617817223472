import { AuthContextType } from '../context/auth-context';
import { MessageContextType } from '../context/message-context';

export const METADATA_URL = '/get-metadata';
export const DELETE_APP_URL = '/delete-app';
export const DOWNLOAD_APP_URL = '/download-app';

export function loadMetadata(
  setIsLoading : Function,
  authContext : AuthContextType,
  setData : Function,
  messageContext : MessageContextType,
): void {

  setIsLoading(true);

  fetch(authContext.getHostUrl() + '/api' + METADATA_URL, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${authContext.getUser()?.accessToken}`,
    },
  })
    .then(async function (response : Response) {
      setData(await response.json());
    })
    .catch((error: Error) => {
      console.log('Error ' + error.message);
      if (error.message === 'NetworkError when attempting to fetch resource.') {
        return [];
      } else {
        messageContext.addError(error);
        return [];
      }
    }).finally(() => setIsLoading(false));
}
