import { useContext } from 'react';
import { Card, CardText, Spinner } from 'reactstrap';
import { AuthContext } from '../context/auth-context';
import { MessageContext } from '../context/message-context';

const Callback = () => {
  const authContext = useContext(AuthContext);
  const messageContext = useContext(MessageContext);

  function getQueryVariable(variable: string) {
    const query = window.location.search.substring(1);
    const vars = query.split('&');

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < vars.length; i++) {
      const pair = vars[i].split('=');
      if (decodeURIComponent(pair[0]) === variable) {
        return decodeURIComponent(pair[1]);
      }
    }

    console.log('Query variable %s not found', variable);
    return 0;
  }

  const authCode = getQueryVariable('code');

  if (authCode) {
    authContext.loadToken(authCode)
      .catch((error: Error) => messageContext.addError(error.message));
  }

  return (
    <Card className="align-items-center p-2 border-0">
      <Spinner/>
      <CardText className="p-2">
        Logging you in …
      </CardText>
    </Card>
  );
};
export default Callback;
