

export default function NotAllowed() {
  return (
    <center>
        <h1 className="p-4">MOD3 CarApp Repository</h1>
        <center className="pt-4">Sorry, you are <b>not allowed</b> to access this page.</center>
        <center className="pt-4">Support and permission requests: <a href="mailto:cms-infotainment@valtech-mobility.com">cms-infotainment@valtech-mobility.com</a></center>
    </center>
  );
}
