import { CSSProperties, useState } from 'react';
import { Box, Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';

import { CarApp } from '../../interfaces/CarApp';
import drawIcon from '../../utils/draw-icon';
import DeleteDialog from './delete-dialog';
import CarAppTable from '../../components/CarAppTable';

interface OverviewTableProps {
  data: CarApp[]
  setupDownload: (CarApp: CarApp) => void
  setupDeletion: (CarApp: CarApp) => void
}

const rowActionsStyling: CSSProperties = {
  display: 'flex',
  flexWrap: 'nowrap',
  gap: '0',
};

export default function OverviewTable({ data, setupDownload, setupDeletion }: OverviewTableProps) {
  const [appToDelete, setAppToDelete] = useState<CarApp>();

  const openDeleteDialog = (app: CarApp) => {
    setAppToDelete(app);
  };

  const closeDeleteDialog = () => {
    setAppToDelete(undefined);
  };

  return (
      <Box sx={{ width: '80%' }}>
          <CarAppTable
              data={data}
              renderRowActions={({ row }) =>
                  <div style={rowActionsStyling}>
                      <Tooltip title='Delete'>
                          <IconButton
                              onClick={() => openDeleteDialog(row.original as CarApp)}
                              sx={{ padding: '4px' }}
                          >
                              {drawIcon('delete')}
                          </IconButton>
                      </Tooltip>
                      <Tooltip title='Download'>
                          <IconButton
                              onClick={() => setupDownload(row.original as CarApp)}
                              sx={{ padding: '4px' }}
                          >
                              {drawIcon('download')}
                          </IconButton>
                      </Tooltip>
                  </div>
              }
          />
        <DeleteDialog
            app={appToDelete}
            onConfirm={() => setupDeletion(appToDelete!)}
            onAbort={closeDeleteDialog}
        />
      </Box>
  );
}