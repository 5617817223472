import { CarApp } from '../../interfaces/CarApp';
import { DELETE_APP_URL, loadMetadata } from '../../utils/load-metadata';
import { MessageContextType } from '../../context/message-context';
import { AuthContextType } from '../../context/auth-context';

export default function deleteCarApp(
  carApp: CarApp,
  setIsLoading: Function,
  messageContext: MessageContextType,
  authContext: AuthContextType,
  setData: Function,
) {

  setIsLoading(true);

  const url = authContext.getHostUrl() + '/api' + DELETE_APP_URL + '?id=' + carApp.id + '&filename=' + carApp.filename;

  if (authContext.getUser() !== null) {
    fetch(url, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${authContext.getUser()?.accessToken}`,
      },
    })
      .then(response => {
        if (response.ok) {
          messageContext.addSuccess('CarApp ' + carApp.filename + ' deleted successfully.');
        } else {
          messageContext.addError('Error, something went wrong!');
        }

        return loadMetadata(setIsLoading, authContext, setData, messageContext);
      })
      .finally(() => setIsLoading(false));
  }
}
