import styled from '@emotion/styled';
import { Logout } from '@mui/icons-material';
import { Menu, Tooltip } from '@mui/material';
import React, { useContext } from 'react';
import { useMatch, useNavigate } from 'react-router-dom';
import { Button, Nav, Navbar } from 'reactstrap';
import { AuthContext } from '../context/auth-context';
import Navigation from './navigation/navigation';
import NavigationItem from './navigation/navigation-item';
import { RequestPermissions } from 'common';
import StageIndikator from './StageIndikator';

interface HeaderProps {
  src?: string
  alt?: string
}

const HorizontalLine = styled.div`
  height: 2px;
  background-color: rgb(0, 30, 80);
  margin-top: 25px;
`;


const Header = ({ alt, src }: HeaderProps) => {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);

  const roles = authContext.getAllowedRoles();
  const roleElements: any[] = [];
  roles.forEach((value: RequestPermissions, key: string) =>
    roleElements.push(<li key={key}>{key} - {value.oem}: {value.requestPermissions.join(', ')}</li>),
  );


  const VWLogo = styled.img`
    src: url(${src});
    alt: ${alt};
    margin-left: 10px;
    margin-right: 10px;
    cursor: pointer;
  `;



  function handleDashboardClick() {
    navigate('/');
  }

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // @ts-expect-error
  function handleKeyDown(event: KeyboardEvent<HTMLElement>) {
    if (event.key === 'Enter') {
      handleDashboardClick();
    }
  }

  const isLogoutPage = Boolean(useMatch('/logoutsuccess'));
  const isCallbackPage = Boolean(useMatch('/callback'));

  if (isLogoutPage || isCallbackPage) {
    return <></>;
  }

  return (
    <>
      <div id="header-placeholder" style={{ height: '100px' }}></div>
      <div className="fixed-top">
        <div className="bg-white d-flex flex-row justify-content-between">
          <div className="stage-select">
            <StageIndikator/>
          </div>
          <Navigation />
          <Navbar
            light
            expand="md"
            className="Navbar-main flex-row align-items-end mw-25"
          >
            <Nav navbar className="flex-row">
              <div className="mx-4">
                <NavigationItem path="/about" text="About" />
              </div>
              <Button variant="outlined" onClick={handleClick}>{authContext.getUser()?.decodedToken?.name}</Button>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <Navbar
                  light
                  expand="md"
                  className="Navbar-main flex-row align-items-end mw-25"
                  tabIndex={-1}
                >
                  <Nav navbar className="flex-column" tabIndex={-1}>
                    <li className="mb-2">{authContext.getUser()?.decodedToken?.name} - {authContext.getUser()?.decodedToken?.preferred_username}.</li>
                    {roleElements}
                    <li className="mt-2">Logout <Logout onClick={authContext.logout} /></li>
                  </Nav>
                </Navbar>
              </Menu>
            </Nav>
          </Navbar>
        </div>
        <div className="d-flex flex-row" style={{ marginTop: '-25px' }}>
          <HorizontalLine className="flex-fill" />
          <Tooltip id="logo-div" placement="bottom" title={'Dashboard'}>
            <div
              onKeyDown={handleKeyDown}
              tabIndex={0}
              role="button"
              id="logo-div"
            >
              <VWLogo
                id="logo"
                alt="Valtech Mobility Logo"
                src="/images/vt_mobility_wortmarke_schwarz.svg"
                width="100"
                onClick={handleDashboardClick}
              />
            </div>
          </Tooltip>
          <HorizontalLine className="w-25" />
        </div>
      </div>
    </>
  );
};

export default Header;
