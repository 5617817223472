import { CloudidpJwtAuthToken, RequestPermissions } from 'common/types/CloudidpJwtAuthToken';


function getFERoles(): Map<string, RequestPermissions> {
  const frontEndRoles = new Map<string, RequestPermissions>();
  frontEndRoles.set('AUDI_SRA_25546', { oem: 'audi', requestPermissions: ['Get'] });
  frontEndRoles.set('AUDI_SRA_25548', { oem: 'audi', requestPermissions: ['Get', 'Post', 'Put', 'Delete'] });
  frontEndRoles.set('AUDI_SRA_25778', { oem: 'bentley', requestPermissions: ['Get'] });
  frontEndRoles.set('AUDI_SRA_25781', { oem: 'bentley', requestPermissions: ['Get', 'Post', 'Put', 'Delete'] });
  frontEndRoles.set('AUDI_SRA_25819', { oem: 'lamborghini', requestPermissions: ['Get'] });
  frontEndRoles.set('AUDI_SRA_25822', { oem: 'lamborghini', requestPermissions: ['Get', 'Post', 'Put', 'Delete'] });
  frontEndRoles.set('audi_sra_ford',  { oem: 'ford', requestPermissions: ['Get', 'Post', 'Put', 'Delete'] });
  frontEndRoles.set('AUDI_SRA_25830', { oem: 'man', requestPermissions: ['Get'] });
  frontEndRoles.set('AUDI_SRA_25833', { oem: 'man', requestPermissions: ['Get', 'Post', 'Put', 'Delete'] });
  frontEndRoles.set('AUDI_SRA_25782', { oem: 'porsche', requestPermissions: ['Get'] });
  frontEndRoles.set('AUDI_SRA_25785', { oem: 'porsche', requestPermissions: ['Get', 'Post', 'Put', 'Delete'] });
  frontEndRoles.set('AUDI_SRA_25808', { oem: 'seat', requestPermissions: ['Get'] });
  frontEndRoles.set('AUDI_SRA_25811', { oem: 'seat', requestPermissions: ['Get', 'Post', 'Put', 'Delete'] });
  frontEndRoles.set('AUDI_SRA_25812', { oem: 'skoda', requestPermissions: ['Get'] });
  frontEndRoles.set('AUDI_SRA_25815', { oem: 'skoda', requestPermissions: ['Get', 'Post', 'Put', 'Delete'] });
  frontEndRoles.set('AUDI_SRA_25770', { oem: 'vw', requestPermissions: ['Get'] });
  frontEndRoles.set('AUDI_SRA_25773', { oem: 'vw', requestPermissions: ['Get', 'Post', 'Put', 'Delete'] });
  frontEndRoles.set('AUDI_SRA_48951', { oem: 'vwtouareg', requestPermissions: ['Get'] });
  frontEndRoles.set('AUDI_SRA_48952', { oem: 'vwtouareg', requestPermissions: ['Get', 'Post', 'Put', 'Delete'] });
  frontEndRoles.set('AUDI_SRA_49599', { oem: 'ford', requestPermissions: ['Get'] });
  frontEndRoles.set('AUDI_SRA_49598', { oem: 'ford', requestPermissions: ['Get', 'Post', 'Put', 'Delete'] });
  return frontEndRoles;
}

export default function getRoles(decodedToken: CloudidpJwtAuthToken) {
  const roles = new Map<string, RequestPermissions>();

  if (!decodedToken.groups) {
    return roles;
  }

  for (const element of decodedToken.groups) {
    if (getFERoles().has(element)) {
      roles.set(element, getFERoles().get(element)!);
    }
  }

  return roles;
}


