import { CarApp } from '../../interfaces/CarApp';
import { DOWNLOAD_APP_URL } from '../../utils/load-metadata';
import { MessageContextType } from '../../context/message-context';
import React from 'react';
import { AuthContextType } from '../../context/auth-context';

export default function downloadCarApp(
  carApp: CarApp,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  messageContext: MessageContextType,
  authContext: AuthContextType,
) {

  setIsLoading(true);

  const url = `${authContext.getHostUrl()}/api${DOWNLOAD_APP_URL}?filename=${carApp.filename}&id=${carApp.id}`;
  fetch(url, {
    method: 'GET',
    headers:{
      Authorization: `Bearer ${authContext.getUser()?.accessToken}`,
    },
  })
    .then((res: Response) => res.blob())
    .then(blob => saveFile(blob, carApp.filename))
    .catch((error: Error) => {
      switch (error.message) {
        case 'res.blob is not a function':
          messageContext.addError('Error with download URL response: Due to the migration there is no downloadable file for this specific carapp.');
          break;
        default:
          messageContext.addError(error.message);
      }
    })
    .finally(() => setIsLoading(false));
}

function saveFile(blob: Blob, filename: string) {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  a.download = filename;
  // document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
}
