
export default function fullAccess(arrayOfRequests: string[]) {
  if (arrayOfRequests.length < 2) return false;

  const allRequestsAlowed: string[] = ['get', 'post', 'put', 'delete'];
  const inputArray: string[] = arrayOfRequests.map(value => value.toLowerCase());
  if (arrayOfRequests.length !== allRequestsAlowed.length) return false;

  for (const x of allRequestsAlowed) {
    const count1 = allRequestsAlowed.filter(e => e === x).length;
    const count2 = inputArray.filter(e => e === x).length;
    if (count1 !== count2) return false;
  }
  return true;
}
