import { Approval, GridView } from '@mui/icons-material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Grid } from '@mui/material';

import DashboardCard from '../components/dashboard-card';

function Dashboard() {
  return (
    <div>
      <Grid container spacing={2} pt={16} justifyContent="center">
        <Grid item md={2}>
          <DashboardCard
            name="Overview"
            icon={<GridView fontSize="large" />}
            link="overview"
          />
        </Grid>
        <Grid item md={2}>
          <DashboardCard
            name="Upload"
            icon={<UploadFileIcon fontSize="large" />}
            link="upload"
          />
        </Grid>
        <Grid item md={2}>
          <DashboardCard
            name="Publish"
            icon={<Approval fontSize="large" />}
            link="publish"
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default Dashboard;
